import React, { Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import WidgetBase from '../../shared/components/widget-base'
import { getClientName } from '../../shared/utils/browser'
import Spinner from '../../UI/spinner'
import fetch from '../../shared/fetch'
import './styles.scss'

interface MatchProps {
  token: string
}

interface Props extends RouteComponentProps<MatchProps>, ComponentBaseProps {
  currentLanguage: string
  logoutOnly: () => void
}

interface State {
  token: string
  loading: boolean
  msg: string
  success: boolean
  sendingToken: boolean
}

declare global {
  interface Window {
    socket: any
  }
}

const STYLE_BASE = 'reregistration'

export default class extends Component<Props, State> {
  state = {
    token: this.props.match.params.token,
    loading: true,
    msg: '',
    success: true,
    sendingToken: false,
  }

  componentDidMount() {
    const { token } = this.state
    const { currentLanguage, logoutOnly } = this.props

    logoutOnly && logoutOnly();

    fetch('/auth/reregistrationCheckToken', {
      method: 'POST',
      body: JSON.stringify({
        token,
        lang: currentLanguage,
        clientName: getClientName(),
        socketId: window.socket.id,
      }),
    })
      .catch(err => {
        this.setState({ msg: err.message, success: false })
      })
      .finally(() => this.setState({ loading: false }))
  }

  resendReregistrationToken = () => {
    const { token } = this.state
    const { currentLanguage } = this.props

    this.setState({ sendingToken: true })

    fetch('/auth/resend-reregistration-email-by-token', {
      method: 'POST',
      body: JSON.stringify({
        token,
        lang: currentLanguage,
      }),
    })
      .catch(() => {
        this.setState({ sendingToken: false })
      })
      .finally(() => this.setState({ sendingToken: false }))
  }

  render() {
    const { loading, success, msg, sendingToken } = this.state
    const { t } = this.props
    const tokenText = ['Token a expirat', 'Токен просрочен', 'Token expired']
    return (
      <WidgetBase title={t('reregistration')}>
        <div className={`panel-body ${STYLE_BASE}`}>
          <div className="panel-content">
            {loading ? (
              <Spinner />
            ) : (
              <Fragment>
                <h2>{success ? t('reregistration.success') : t('reregistration.failed')}</h2>
                <h3>{msg}</h3>



                {tokenText.findIndex(token => token.toLowerCase() === msg.toLowerCase()) > -1 &&
                  <button
                    type="button"
                    onClick={() => this.resendReregistrationToken()}
                    disabled={sendingToken}
                    className="button button-orange button-block resend-token"
                  >
                    {t('send')}
                  </button>
                }
              </Fragment>
            )}
          </div>
        </div>
      </WidgetBase>
    )
  }
}
