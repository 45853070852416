import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { Link, Redirect } from 'react-router-dom'
import { Field } from 'redux-form'
import { Popover, PopoverBody } from 'reactstrap'
import { toastr } from 'react-redux-toastr'
import FlexBand, { FlexBandItem } from 'flexband'

import Captcha from '../../../captcha'
import { ModalConsumer, ModalContextState } from '../../../../context/modal-context'
import WidgetBase from '../../../shared/components/widget-base'
import FilterFieldRender from '../../../UI/filter-field'
import Map from '../../../UI/openstreetmaps-widget'
import Dropdown from '../../../UI/dropdown'
import { CheckboxField } from '../../../UI/checkbox'
import dict from '../../../shared/dictionaries'
import fetch from '../../../shared/fetch'
import ProfileMini from '../../../user/profile-mini'
import DateSelectorCalendar from '../../../shared/components/date-selector-calendar'
import CountryCityRadiusFromTo from '../../../shared/components/country-city-radius_from-to'
import BaseData from '../../autopark/add/form/car-base-data'
import LoadData from '../../autopark/add/form/car-load-data'
import CargoData from '../../autopark/add/form/cargo-data'
import CarPhoto from '../../autopark/add/form/car-photo'
import TransportationInfo from '../../../cargo/add/transportation'
import AvailableDocs from '../../autopark/add/form/available-docs'
import { required } from '../../../shared/form-validators'
import getFormInputNames from '../../../shared/getFormInputNames'
import SaveAsTemplateForm from '../../../cargo/add/form/save-as-template'
import { canDo, addScrollBar } from '../../../../store/utils'
import { BUSE_ID, CARGO_ID, TRACTOR_ID, CARGO_LARGE_ID } from '../../autopark/autopark-constants'
import { AddTransportProps, AddTransportState } from './types'
import config from '../../../../config'
import PopoverWrapper from '../../../UI/popover-wrapper'

import './styles.scss'

const STYLE_BASE = 'cargo-add_'

export default class extends Component<AddTransportProps, AddTransportState> {
  modalConsumer: ModalContextState
  item: ICargoTransportEdit

  constructor(props: AddTransportProps) {
    super(props)

    this.state = {
      redirectTo: undefined,
      enterManually: true,
      hasTrailer: false,
      popoverId: null,
      carTypes: dict.carTypes(props.t),
      mapCoordsFocused: '',
      captchaVerified: false,
      mainConfig: {},
      mapPoints: {
        from: {
          value: '',
          coords: null,
          marker: null,
        },
        to: {
          value: '',
          coords: null,
          marker: null,
        },
        middlePoints: [],
      },
      mapInitValues: {
        from: {
          country: null,
          city: null,
          coords: null,
        },
        to: {
          country: null,
          city: null,
          coords: null,
        },
      },
      autoparkCars: [],
      folder: "transport"
    }
  }

  componentDidMount() {
    const { isAuthenticated, getTemplates, getItem, initialize, match, permissions, onCanAdd, change } = this.props
    const { itemId } = match.params
    this.setState({ mainConfig: config })
    change('transport.quantity', 1)
    if (isAuthenticated) {
      onCanAdd("transport")
      this.updateAutoparkCars()
      getTemplates('transport')
      if (itemId) {
        getItem({ itemId, isEdit: true }).then(() => {
          this.item = this.props.currentTransport
          if (this.props.currentTransport.from?.address) {
            this.item.filterKey = 'manual'
          } else {
            this.item.filterKey = 'choose'
          }
          initialize(this.item)
          this.setInitPoints(this.item)
        })
      }
    } else {
      onCanAdd("cargo")
    }
    if (canDo(permissions, 'captcha', 'skip')) {
      this.setState({ captchaVerified: true })
    }
    addScrollBar()
  }

  updateAutoparkCars = () => {
    fetch('/transport/autopark/all').then(result => {

      const autoparkCars = result.d
      const userCar = autoparkCars.filter(car => !car.companyId) 
      const companyCar = autoparkCars.filter(car => car.companyId) 
      // dropdown delimiter (company cars / user cars)
      if (companyCar.length > 0 && companyCar[0]?.companyId) {
        companyCar[0].companyDelimiter = true
      }
      if (userCar.length > 0 && !userCar[0]?.companyId) {
        userCar[0].userDelimiter = true
      }
      this.setState({ autoparkCars: [...userCar, ...companyCar] })
    })
  }

  onSelectCar = autoparkId => {
    if (typeof autoparkId === 'number') {
      const { autoparkCars } = this.state
      const car = autoparkCars.find(x => x.itemId === `${autoparkId}`)

      if (!car) return
      this.mapAutoparkToItem(car)
      this.setState({ enterManually: false })
    } else {

      const emptyDataForSelect = {
        active: null,
        adrClass: 1,
        car: { makeAndModel: null, stateNumber: null, bodyType: null, year: null, ecoStandard: null },
        carType: null,
        companyId: null,
        isAdr: null,
        isClimateControl: null,
        isCmr: null,
        isCmrInsurance: null,
        isCustom: null,
        isDeclaration: null,
        isGps: null,
        isGrouping: null,
        isLoadingRare: null,
        isLoadingSide: null,
        isLoadingTop: null,
        isOversized: null,
        isSanitary: null,
        isTir: null,
        itemId: null,
        name: null,
        notes: null,
        ownershipType: null,
        photo: [],
        temperatureFrom: 0,
        temperatureTo: 0,
        trailer: { makeAndModel: null, stateNumber: null, bodyType: null, year: null },
        userDelimiter: null,
        value: null,
        volume: { m3: null, ldm: null, pal: null },
        weight: null,
      }
      this.mapAutoparkToItem(emptyDataForSelect)
      this.setState({ enterManually: true })
    }
  }

  setInitPoints = item => {
    const { from, to, middlePoints, carType } = item
    const { carTypes } = this.state
    const transport = carTypes.find(x => x.value === carType)
    this.setState({
      mapInitValues: {
        from: {
          country: from?.countryId,
          city: from?.cityId,
          coords: from?.coords,
        },
        to: {
          country: to?.countryId,
          city: to?.cityId,
          coords: to?.coords,
        },
        middlePoints,
      },
      enterManually: true,
      hasTrailer: transport?.hasTrailer,
    })
  }

  componentDidUpdate(prevProps: AddTransportProps) {
    const { initialValues, isAuthenticated, match, carType, getTemplates, initialize } = this.props
    const { itemId } = match.params
    const { carTypes } = this.state

    if (isAuthenticated !== prevProps.isAuthenticated) {
      getTemplates('transport')
    }
    if (prevProps.template !== this.props.template) {
      this.setInitPoints(initialValues)
      initialize(initialValues)
    }
    if (prevProps.match.params.itemId !== itemId) {
      if (itemId) {
        initialize(this.item)
      } else {
        initialize(initialValues)
      }
    }
    if (prevProps.carType !== carType) {
      const hasTrailer = carTypes.find(car => carType === car.value)?.hasTrailer
      this.setState({ hasTrailer })
    }
  }

  mapAutoparkToItem = car => {
    const { change } = this.props
    const { makeAndModel: modelCar, stateNumber: numberCar, ...restCar } = car.car
    const { makeAndModel: modelTr, stateNumber: numberTr, ...restTr } = car.trailer
    const {
      weight,
      isLoadingRare,
      isLoadingSide,
      isLoadingTop,
      photo,
      isGrouping,
      isOversized,
      adrClass,
      isAdr,
      isClimateControl,
      temperatureFrom,
      temperatureTo,
      ownershipType,
      carType,
      isCmr,
      isCustom,
      isDeclaration,
      isTir,
      isSanitary,
      notes,
      volume,
      isGps
    } = car

    change('photo', photo)
    change('transport', {
      ...restCar,
      model: modelCar,
      number: numberCar,
      weight,
      isLoadingRare,
      isLoadingSide,
      isLoadingTop,
      recurring: "one-time",
      quantity: 1
    })
    change('trailer', { ...restTr, model: modelTr, number: numberTr })
    //change('docs', { isCmr, isCustom, isDeclaration, isSanitary, isTir })
    change('isCmr', isCmr)
    change('isCustom', isCustom)
    change('isDeclaration', isDeclaration)
    change('isSanitary', isSanitary)
    change('isTir', isTir)
    change('carType', carType)
    change('ownershipType', ownershipType)
    change('cargo.isGrouping', isGrouping > 0 ? true : false)
    change('cargo.isOversized', isOversized > 0 ? true : false)
    change('cargo.isClimateControl', isClimateControl > 0 ? true : false)
    change('cargo.isGps', isGps > 0 ? true : false)
    change('cargo.temperatureFrom', temperatureFrom)
    change('cargo.temperatureTo', temperatureTo)

    change('cargo.adrClass', adrClass)
    change('cargo.isAdr', isAdr > 0 ? true : false)
    change('cargo.carNotes', notes)
    change('volume', volume)
    
  }

  handleFilterChange = value => {
    const { mapPoints } = this.state
    const { change } = this.props

    if (value !== 'manual') {
      // eslint-disable-next-line no-unused-vars
      for (const i in mapPoints) {
        if (i != 'from' && i != 'to') continue

        const { countryId = null, cityId = null } = mapPoints[i]
        const initCountry = this.props[`${i}CountryId`]
        const initCity = this.props[`${i}CityId`]

        if (countryId) {
          if (countryId !== initCountry) {
            change(`${i}.countryId`, countryId)
            change(`${i}.cityId`, null)
          }

          if (cityId !== initCity || !cityId) {
            change(`${i}.cityId`, cityId)
          }
        }
        // else {
        //   change(`${i}.countryId`, null)
        // }
      }
    }
  }

  getCurrentCarType = (): string => {
    return this.state.carTypes.find(car => this.props.carType === car.value)?.nameEng
  }

  renderPopover = butId => {
    return (
      <Popover
        placement="top"
        isOpen={this.state.popoverId === butId}
        target={butId}
        toggle={() => {
          this.setState({ popoverId: null })
        }}
      >
        <PopoverBody>{this.props.t('validation.not-all-required-fields-entered')}</PopoverBody>
      </Popover>
    )
  }

  onChooseChange = () => {
    const { change } = this.props
    change('from.coords', null)
    change('to.coords', null)
    change('from.address', null)
    change('to.address', null)
    this.setState({
      mapPoints: {
        from: {
          value: '',
          coords: null,
          marker: null,
        },
        to: {
          value: '',
          coords: null,
          marker: null,
        },
        middlePoints: [],
      },
    })
  }

  onClickMap = (place, insertingItem) => {
    const { mapPoints } = this.state
    const { change } = this.props

    if (!place.value || place.value === '') {
      place.coords = null
    }

    const insItemSplitted = insertingItem.split('-')

    let insertingPlace
    if (insItemSplitted[0] === 'middlePoint') {
      insertingPlace = mapPoints.middlePoints[insItemSplitted[1]]
    } else {
      insertingPlace = mapPoints[insertingItem]
      change(insertingItem, place)
    }

    // eslint-disable-next-line no-unused-vars
    for (const i in place) {
      insertingPlace[i] = place[i]
    }
    this.setState({ mapPoints })
  }

  onMapAddMiddlePoint = (): Promise<number> => {
    return new Promise((resolve, reject) => {
      const { middlePoints } = this.state.mapPoints

      middlePoints.length <= 10 &&
        middlePoints.push({
          value: '',
          coords: null,
          marker: null,
          isLoadingPlace: false,
          city: null,
          country: null,
        })

      this.forceUpdate(() => {
        try {
          resolve(middlePoints.length - 1)
        } catch (e) {
          reject(e)
        }
      })
    })
  }

  onMapRemoveMiddlePoint = id => {
    this.state.mapPoints.middlePoints.splice(id, 1)

    this.forceUpdate()
  }

  handlePointLoadingPlace = id => {
    const { middlePoints } = this.state.mapPoints

    middlePoints[id].isLoadingPlace = !middlePoints[id].isLoadingPlace

    this.forceUpdate()
  }

  onAdd = (butId: string, createAnother?: boolean) => {
    const { t, handleSubmit, reset, change, touch, invalid, enteredValues, match, getUserStatistics, save } = this.props
    const { itemId } = match.params
    const {
      mapPoints: { from, to, middlePoints },
    } = this.state

    delete from.marker
    delete to.marker

    middlePoints.forEach(point => {
      delete point.marker
    })

    const formNames = getFormInputNames(enteredValues, '')

    touch(...formNames)

    if (invalid || !from || !to) {
      this.setState({ popoverId: butId })
      return
    }

    const middlePointsString = JSON.stringify(middlePoints)

    handleSubmit(values =>
      save(
        { ...values, mapPoints: { from, to }, middlePoints: middlePointsString, itemId },
        response => {
          itemId
            ? toastr.success(t('transport.edited'), t('transport.message.success.edit'), {
              timeOut: 4000,
            })
            : toastr.success(t('transport.added'), t('transport.message.success'), {
              timeOut: 4000,
            })
          getUserStatistics('profile')
          if (createAnother) {
            change('template', null)
            reset()
            window.location.reload()
          } else {
            this.setState({ redirectTo: `/transport/${response.transport_id}` })
          }
        }
      )
    )()
  }

  onCreate = () => this.onAdd('add')
  onCreateAndContinue = () => this.onAdd('add-and-create', true)

  renderHeaderMenu = () => {
    const { templates, t } = this.props

    const items = [
      ...templates.map(({ name, id }) => ({
        name,
        value: parseInt(id),
      })),
    ]

    return (
      <div className="select-template">
        <div className="select-container">
          <Field
            name="template"
            component={Dropdown}
            items={items}
            emptyOption={t('complete.by.template')}
            itemDeleting
            onDeleteItem={this.onDeleteTemplate}
          />
        </div>
      </div>
    )
  }

  changeInputsValues = (quantity, recurring, isLoadingTop) => {
    const { change } = this.props
    change('transport.types', [])
    change('transport.quantity', quantity)
    change('transport.recurring', recurring)
    change('transport.isLoadingTop', isLoadingTop)
    change('transport.isLoadingRare', null)
    change('transport.isLoadingSide', null)
  }

  onChangeShippingType = () => {
    const { shippingType } = this.props
    if (shippingType === 'avia') {
      this.changeInputsValues(1, null, null)
    } else {
      this.changeInputsValues(1, 0, true)
    }
  }

  onDeleteTemplate = (id: number) => {
    this.props.deleteTemplate(id, 'transport')
  }

  onSaveAsTemplate = ({ name }) => {
    this.props.saveAsTemplate({ name, item: this.props.enteredValues }, 'transport')
    this.modalConsumer.clearModal()
  }
  showSaveAsTemplate = () => {
    const { t } = this.props
    const { showModalComponent, clearModal } = this.modalConsumer
    showModalComponent(
      <SaveAsTemplateForm onSave={this.onSaveAsTemplate} onCancel={clearModal} />,
      {
        title: t('save.as.template'),
        className: `${STYLE_BASE}modal`,
        backdrop: true,
      }
    )
  }

  handleImageError = e => {
    this.setState({ folder: "auto-park" })
  }

  render() {
    const { t } = this.props
    const title = t('transport.add')

    const {
      redirectTo,
      hasTrailer,
      enterManually,
      carTypes,
      mapPoints,
      mapInitValues,
      captchaVerified,
      autoparkCars,
      mainConfig,
      folder
    } = this.state

    const {
      filterKey,
      fromCountryId,
      toCountryId,
      submitting,
      change,
      langId,
      notes = '',
      shipOnDate,
      userId,
      form: formName,
      shippingType,
      match,
      carType,
      permissions,
      isLoadingSide,
      isLoadingTop,
      isLoadingRare,
      //currentTransport
      canAdd,
      enteredValues
    } = this.props

    const { itemId } = match.params

    const filterItems = dict.cargoAddFilterItems(t)
    const checked = isLoadingSide || isLoadingTop || isLoadingRare
    const noRequiredFieldsForTransport = !enteredValues?.transport.model || !enteredValues?.transport.year
    const noRequiredFieldsForTrailer = !enteredValues?.trailer.model || !enteredValues?.trailer.bodyType || !enteredValues?.trailer.year
    const disabled = submitting || !captchaVerified || shippingType === 'auto' && ( !checked || (!itemId && !canAdd) || (hasTrailer && noRequiredFieldsForTrailer) || noRequiredFieldsForTransport)
    const ownershipTypes = dict.ownershipTypesCars(t)
    const showCaptcha = !canDo(permissions, 'captcha', 'skip')

    if (redirectTo) {
      return <Redirect to={redirectTo} />
    }

    const currentTransport = this.getCurrentCarType()

    const imgFullPath = `${mainConfig.apiUrl}/common/file/transport/${userId}/${this.item?.photos[0]}`

    return (
      <form>
        <WidgetBase
          title={itemId ? `${t('transport.edit')} (id ${itemId})` : title}
          headerMenu={this.renderHeaderMenu}
          className={classNames({
            [`${STYLE_BASE}wrapper`]: true,
          })}
        >
          <div data-fix-col="true" className="panel-body" onClick={() => this.setState({ popoverId: null })}>
            <Field
              name="filterKey"
              component={FilterFieldRender}
              items={filterItems}
              onFilterClick={this.handleFilterChange}
              className="tabs-list"
              loadLanguages
            />
            <div className="panel-content">
              <div className="panel-form">
                {filterKey === 'manual' ? (
                  <Map
                    className={classNames({
                      'map-container': true,
                      [`${STYLE_BASE}map-container`]: true,
                    })}
                    onClick={this.onClickMap}
                    onMapAddMiddlePoint={this.onMapAddMiddlePoint}
                    onMapRemoveMiddlePoint={this.onMapRemoveMiddlePoint}
                    handlePointLoadingPlace={this.handlePointLoadingPlace}
                    mapPoints={mapPoints}
                    mapInitValues={mapInitValues}
                    itemLabelFrom={t('transport.location.from')}
                    itemLabelTo={t('transport.location.to')}
                  />
                ) : (
                  <CountryCityRadiusFromTo
                    className="panel-content-row"
                    titleFrom={t('transport.location.from')}
                    titleTo={t('transport.location.to')}
                    radiusTitleFrom={t('location.in.range')}
                    radiusTitleTo={t('location.in.range')}
                    fromCountryId={fromCountryId}
                    onChange={this.onChooseChange}
                    toCountryId={toCountryId}
                  />
                )}
                <div className="panel-content-row" style={{ height: '100%' }}>
                  <div className="panel-form-row">
                    <div className="row">
                      <DateSelectorCalendar
                        className="col-xl-8"
                        shipOnDate={shipOnDate}
                        basePropName="shipOn"
                        change={change}
                        formName={formName}
                        disableOutdated
                      />
                    </div>
                  </div>
                </div>
                <TransportationInfo
                  itemId={itemId || null}
                  langId={langId}
                  entityType="transport"
                  shippingType={shippingType}
                  onChange={this.onChangeShippingType}
                />
                {shippingType === 'auto' && (
                  <div className="panel-content-row car-select">
                    <div className="panel-form-row">
                      <span className="label">{t('transport.vehicle.data')}</span>
                      <div className="row">
                        <div className="col-sm-8">
                          <p>
                            {t('choose.from')}{` `}
                            <Link to="/transport/autopark">{t('from.autopark')}</Link>
                            {` `}{t('or.enter.manually')}
                          </p>
                          <p className="col-gray fs-11">{t('selected.vehicle')}</p>
                        </div>
                        <div className="col-sm-8">
                          <div className="select-custom-lg">
                            <Field
                              name="transport.selectedCarId"
                              component={Dropdown}
                              items={autoparkCars}
                              style={{ width: '150px' }}
                              emptyOption={`${t('enter.data.manually')}`}
                              onChange={this.onSelectCar}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <img src={imgFullPath} alt={"Transport Car"} className="img-trigger-to-show-car-photo-from-autopark" onError={this.handleImageError} />
                {enterManually
                  ? [
                    shippingType === 'auto' && [
                      <div className="panel-content-row" key={0}>
                        <div className="panel-form-row">
                          <div className="row">
                            <FlexBand
                              className="transport-type-block"
                              justify="space-around"
                              wrap="wrap"
                              alignItems="baseline"
                            >
                              <FlexBandItem className="transport-icon-block" align="center">
                                <span className="icon-truck icon-truck-change">
                                  <i
                                    className={classNames({
                                      'ico-placeholder': true,
                                      'ico-truck-buse': carType === BUSE_ID,
                                      'ico-truck-cargo': carType === CARGO_ID,
                                      'ico-truck-tractor': carType === TRACTOR_ID,
                                      'ico-truck-cargo-lg': carType === CARGO_LARGE_ID,
                                    })}
                                  />
                                </span>
                              </FlexBandItem>
                              <FlexBandItem className="car-type">
                                <label className="required-field">{t('car.type')}</label>
                                <Field
                                  name="carType"
                                  component={Dropdown}
                                  items={carTypes}
                                  emptyOption={t('choose.one')}
                                  validate={required}
                                />
                              </FlexBandItem>
                              <FlexBandItem className="ownership-type">
                                <label>{t('car.ownership')}</label>
                                <Field
                                  name="ownershipType"
                                  component={Dropdown}
                                  items={ownershipTypes}
                                  emptyOption={t('choose.one')}
                                />
                              </FlexBandItem>
                            </FlexBand>
                          </div>
                        </div>
                      </div>,
                      carType && currentTransport && [
                        <BaseData
                          key={1}
                          title={t(`${currentTransport}.data`)}
                          formName={formName}
                          makeAndModelLabel={t(`transport.${currentTransport}.model`)}
                          makeAndModelName="transport.model"
                          stateNumberName="transport.number"
                          bodyTypeName="transport.bodyType"
                          yearName="transport.year"
                          ecoStandardName="transport.ecoStandard"
                          currentTransport={currentTransport}
                          {...{ carType }}
                        />,
                        hasTrailer && (
                          <BaseData
                            key={2}
                            title={t('transport.trailer.data')}
                            formName={formName}
                            makeAndModelLabel={t('transport.trailer.model')}
                            makeAndModelName="trailer.model"
                            stateNumberName="trailer.number"
                            bodyTypeName="trailer.bodyType"
                            yearName="trailer.year"
                            langId={langId}
                          />
                        ),
                      ],
                    ],
                    <LoadData
                      key={3}
                      isTransport
                      title={
                        shippingType === 'auto'
                          ? t('transport.load.characteristics.auto')
                          : shippingType === 'avia'
                            ? t('transport.load.characteristics.avia')
                            : t('transport.load.characteristics.container')
                      }
                      weightName="transport.weight"
                      loadingTopName="transport.isLoadingTop"
                      loadingSideName="transport.isLoadingSide"
                      loadingRareName="transport.isLoadingRare"
                      formName={formName}
                      shippingType={shippingType}
                    />,
                    <CargoData
                      key={4}
                      adrName="cargo.isAdr"
                      adrClassName="cargo.adrClass"
                      oversizedName="cargo.isOversized"
                      groupingName="cargo.isGrouping"
                      gpsName="cargo.isGps"
                      shippingType={shippingType}
                    />,

                    shippingType === 'auto' && <AvailableDocs key={5} />,
                    <CarPhoto
                      key={6}
                      folder={`${folder}/${userId}`}
                      photos={this.item?.photos}
                      change={change}
                    />,
                  ]
                  : null}
                {shippingType === 'auto' && (
                  <div className="panel-content-row">
                    <div className="panel-form-row">
                      <span className="label">{t('preferred.payment.method.optional')}</span>
                      <div className="checkboxes-light clearfix">
                        {dict.cargo_options.map((column, j1) => (
                          <div className="panel-form-col-md" key={j1}>
                            {column.map((row, j2) => {
                              return (
                                <div className="input-container" key={j2}>
                                  <CheckboxField name={'options.' + row.fieldName}>
                                  {t(row.displayName)}
                                  </CheckboxField>
                                </div>
                              )
                            })}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="panel-content-row">
                  <div className="panel-form-row clearfix">
                    <span className="label fl-left">{t('addition-info-optional')}</span>
                    <span className="col-gray length-countdown fl-right">
                      <span className="count">{notes && notes.length > 0 ? notes.length : 0}</span>
                      {` / `}
                      <span className="length">250</span>
                    </span>
                    <Field
                      name="notes"
                      component="textarea"
                      placeholder={t('character.limit.250')}
                      style={{ height: '100px', marginBottom: '10px' }}
                      maxLength={250}
                    />
                  </div>
                  <div className="captcha-position">
                    {showCaptcha && (
                      <Captcha
                        verifyCallback={(key) => {
                          this.setState({ captchaVerified: true });
                          this.props.change('captchaKey', key);
                        }}
                        expiredCallback={() => this.setState({ captchaVerified: false })}
                      />
                    )}
                    <div>
                      <span className="color-red">*</span> - {t('required.field')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WidgetBase>

        <p className="fs-14"> {t('contact.details.publishing')} </p>

        <ProfileMini />

        <div className="btns-template clearfix">
          <ModalConsumer>
            {modalConsumer => {
              this.modalConsumer = modalConsumer
              return (
                <Link to="#" className="link-button fl-left" onClick={this.showSaveAsTemplate}>
                  {t('save.as.template')}
                </Link>
              )
            }}
          </ModalConsumer>
          {!itemId && (
            <Fragment>
              <button
                className="button button-orange fl-right"
                disabled={disabled}
                type="button"
                id="add-and-create"
                onClick={this.onCreateAndContinue}
              >
                {t('add.and.create.another')}
              </button>
              {this.renderPopover('add-and-create')}
            </Fragment>
          )}
          <div className="allow-btn-container">
            <button
              className="button button-orange fl-right"
              disabled={disabled}
              type="button"
              id="add"
              onClick={this.onCreate}
            >
              {itemId ? t('save.changes') : t('add')}
            </button>
            {!itemId && !canAdd && <PopoverWrapper text={t('transport.limit')} hover>
              <i className="fa fa-question-circle m-l-5" style={{ marginTop: 8 }} />
            </PopoverWrapper>}
          </div>
          {this.renderPopover('add')}
        </div>
      </form>
    )
  }
}
