import React, { Component, KeyboardEvent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Cookies from 'universal-cookie'
import ChangePasswordForm from '../auth/change-password'
import HeaderTop from '../navigation/header-top'
import HeaderBottom from '../navigation/header-bottom'
import CookieModal from '../cookie-modal'
import ReregisterModal from '../reregister-modal'
import Footer from './footer'
import LoginGuard from '../auth/login/side-login-guard'
import constants from '../../store/constants'
import { ModalConsumer, ModalContextState } from '../../context/modal-context'
import { showModal, logout, showForm, closeForm, setActivePopup } from '../../store/actions'
import { hideScrollBar } from "../../store/utils"

import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'

interface ILayoutProps extends ComponentBaseProps, RouteComponentProps {
  isAuthenticated: boolean
  setActivePopup: typeof setActivePopup
  showAuthModal: typeof showModal
  showForm: typeof showForm
  closeForm: typeof closeForm
  logout: typeof logout
  initialValues?: any
}

interface ILayoutState {
  approveCookies: boolean
  showSideDrawer: boolean
}

declare global {
  interface Window {
    t: ComponentBaseProps['t']
  }
}

export default class extends Component<ILayoutProps, ILayoutState> {
  modalConsumer: ModalContextState

  state = {
    approveCookies: false,
    showSideDrawer: null,
  }

  componentDidMount() {
    const { isAuthenticated, initialValues: { legacyPassword } } = this.props
    const cookies = new Cookies()
    const approveCookies = cookies.get('approveCookies')
    this.setState({ approveCookies })

    if (legacyPassword) {
      // login modal closes 500ms
      setTimeout(() => this.showItemModal(), 600)
    }

    const approveInfo = localStorage.getItem('approveInfo') === 'true' || isAuthenticated
    if (!approveInfo) this.showReregisterModal()
  }

   showItemModal = () => {
      const { legacyPassword } = this.props.initialValues
      const { showModalComponent, clearModal } = this.modalConsumer
      const modalItem =
          <ChangePasswordForm clearModal={clearModal} isLegacyPassword={legacyPassword} />
       
      hideScrollBar()
      showModalComponent(modalItem, {
        hideHeader: true,
        skipLayout: true,
        backdrop: !legacyPassword || 'static',
        keyboard: !legacyPassword,
        className: 'change-password-email-modal',
      })
    }

  onClick = () => {
    this.onCloseAll()
  }
  onKeyUp = (event: KeyboardEvent) => {
    const { keyCode } = event
    if (keyCode) {
      this.onCloseAll()
    }
  }
  onCloseAll = () => {
    this.props.setActivePopup(undefined)
  }

  sideDrawerToggleHandler = () => {
    this.setState((prevState: ILayoutState) => {
      return { showSideDrawer: !prevState.showSideDrawer }
    })
  }
  openLoginModal = () => {
    this.props.showAuthModal(constants.AUTH_MODES.LOGIN)
  }
  openRegistrationForm = () => {
    this.props.showAuthModal(constants.AUTH_MODES.REGISTRATION)
  }
  closeModalForm = () => {
    this.props.closeForm()
  }
  logout = () => {
    this.props.logout()
    this.props.history.push('/')
  }

  setCookie = () => {
    const cookies = new Cookies()
    cookies.set('approveCookies', true, {
      path: "/",
      maxAge: 604800
  })
    this.setState({ approveCookies: true })
  }

  showReregisterModal = () => {
    const { showModalComponent, clearModal } = this.modalConsumer

    const setApproveInfo = () => localStorage.setItem('approveInfo', 'true')

    showModalComponent(
      <ReregisterModal setApproveInfo={setApproveInfo} clearModal={clearModal} />,
      {
        hideHeader: true,
        className: 'popup reregistration-info-modal',
        backdrop: 'static',
        keyboard: false,
        skipLayout: true,
      }
    )
  }

  render() {
    window.t = this.props.t
    const { approveCookies } = this.state

    return (
      <div onClick={this.onCloseAll} onKeyUp={this.onKeyUp}>
        <div className="main-wrapper" style={{ minHeight: '0px' }}>
          <header className="header">
            <HeaderTop />
            <HeaderBottom
              isAuthenticated={this.props.isAuthenticated}
              openLoginForm={this.openLoginModal}
              openRegistrationForm={this.openRegistrationForm}
              logout={this.logout}
            />
          </header>

          <div className="main-content">
            <div className="container">{this.props.children}</div>
          </div>
        </div>

        <Footer t={this.props.t} />

        <LoginGuard />

        <ModalConsumer>
          {modalConsumer => {
            this.modalConsumer = modalConsumer
            return null
          }}
        </ModalConsumer>

        {!approveCookies && <CookieModal setCookie={this.setCookie} />}
      </div>
      
    )
  }
}
function maxAge(arg0: number): import("universal-cookie").CookieSetOptions {
  throw new Error('Function not implemented.')
}

