import React, { Fragment } from 'react'
import classNames from 'classnames'

import { Link } from 'react-router-dom'
import { SHOW_ADS_MIN_ITEMS } from '../../../components/shared/utils'
import { plus } from 'react-icons-kit/fa/plus'
import config from '../../../config'
import Icon from 'react-icons-kit'
import fetch from '../../shared/fetch'
import { Field } from 'redux-form'
import Banner from '../../banner'
import 'react-confirm-alert/src/react-confirm-alert.css'
import moment from 'moment'
import { CompanyListProps, CompanyListState } from './types'
import TooltipWrapper from '../../UI/popover-wrapper'
import { ModalConsumer } from '../../../context/modal-context'
import Filter from '../../shared/components/widget-filter'
import dict from '../../shared/dictionaries'
import { CheckboxField } from '../../UI/checkbox'
import Pagination from '../../UI/pagination'
import Dropdown from '../../UI/dropdown'
import WidgetHeader from '../../shared/components/widget-header'
import { canDo } from '../../../store/utils'
import './styles.scss'
import Spinner from '../../UI/spinner'
import BussinesTypes from './businnes-types'
import { confirmAlert } from 'react-confirm-alert'
import { dateFormatter, dateTimeFormatter, capitalize } from '../../shared/utils'
const STYLE_BASE: string = 'company-list_'
const NUMBER_OF_ROWS_TO_INSERT_AD: number = 5

export default class extends React.Component<CompanyListProps, CompanyListState> {
  clearModal: any
  showModalComponent: any

  constructor(props: CompanyListProps) {
    super(props)

    this.state = {
      categories: {},
      isOpen: false,
      confirmItemId: undefined,
      companyRequestVerificationList: [],
      pageIndexVerified: 0,
      pageSizeVerified: 10,
      totalVerified: 0,
      accessStatus: '',
      showOnlyVerificationRequests: true,
      verifiedOnly: false,
      closedOnly: false,
      isAdmin: false,
      showExpiredRoleOnly: false,
      showGoldOnly: false,
      showUnregistered: false,
      showGoldLightOnly: false,
      showFreeOnly: false,
      showInBlockOnly: false,
      errorsImages: [],
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { searchText },
      },
      filterKey,
      change,
      t,
      profile,
      permissions
    } = this.props
    const isAdmin = canDo(permissions, 'admin')

    let categories
    if (profile?.accessStatus === 'Admin') {
      categories = dict.companyListFilterItemsForAdmin(t)
      this.getCompanyVerificationRequest(true)
    } else {
      categories = dict.companyListFilterItems(t)
    }

    this.setState({ categories, accessStatus: profile?.accessStatus, isAdmin, showUnregistered: false })
    change('searchText', searchText);
    change("showExpiredRoleOnly", false);
    change("showUnregistered", false);
    change("showFreeOnly", false);
    change("showInBlockOnly", false);
    change("showGoldOnly", false);
    change("showGoldLightOnly", false);
    const blackListOnly = filterKey === 'blacklist'

    setTimeout(() => {
      this.refresh({ searchText, blackListOnly })
    }, 1000);

  }

  getCompanyVerificationRequest = activeOnly => {
    const { pageIndexVerified, pageSizeVerified } = this.state
    fetch('/company/verification', {
      method: 'POST',
      body: JSON.stringify({
        pageIndex: pageIndexVerified,
        pageSize: pageSizeVerified,
        activeOnly,
      }),
    }).then(res => {
      if (res.ok) {
        const { pageIndex, pageSize, totalItems } = res
        this.setState({
          pageIndexVerified: pageIndex,
          pageSizeVerified: pageSize,
          totalVerified: totalItems,
          companyRequestVerificationList: res?.items || [],
        })
      }
    })
  }

  componentDidUpdate(prevProps: CompanyListProps) {
    const { change, t, isAuthenticated, langId, match, filterKey, pageSize, pageIndex } = this.props
    const {
      isAuthenticated: isAuthOld,
      langId: langIdOld,
      match: matchOld,
      filterKey: filterKeyOld,
      pageSize: pageSizeOld,
      pageIndex: pageIndexOld
    } = prevProps
    const { searchText } = match.params
    const { searchText: searchTextOld } = matchOld.params

    const { showOnlyVerificationRequests } = this.state

    if (isAuthenticated !== isAuthOld && filterKey !== 'verification') {
      this.refresh({ pageIndex: 0 })
    }
    if (langId !== langIdOld && filterKey !== 'verification') {
      this.setState({ categories: dict.companyListFilterItems(t) })
    }

    if (filterKey !== filterKeyOld && !searchText && filterKey !== 'blacklist' && filterKey !== 'verification') {
      this.refresh({ pageIndex: 0 })
    } else if (searchText !== searchTextOld && filterKey !== 'verification') {
      change('searchText', searchText)
      this.refresh({ pageIndex: 0, searchText })
    }
    if (filterKey !== filterKeyOld && filterKey === 'blacklist' && filterKey !== 'verification') {
      this.refresh({ filterKey, blackListOnly: true })
    }
    if (pageSize !== pageSizeOld || pageIndex !== pageIndexOld) {

      if (filterKey === 'verification') {
        this.setState({
          pageIndexVerified: pageIndex,
          pageSizeVerified: pageSize,
        }, () => {
          this.getCompanyVerificationRequest(showOnlyVerificationRequests)
        })
      }
    }
  }

  getGroups() {
    const { items = [], pageSize } = this.props
    let chunk = []
    const groups = [chunk]

    items.forEach((x, j) => {
      if (j < pageSize) {
        if (j === 0) {
          chunk = groups[0]
        } else if (j % NUMBER_OF_ROWS_TO_INSERT_AD === 0) {
          chunk = []
          groups.push(chunk)
        }
        chunk.push(x)
      }
    })
    return groups
  }

  refresh = (params = {}) => {
    const {
      languages,
      pageIndex,
      pageSize,
      filterKey,
      searchText,
      setParams,
      country,
      sortBy,
      businessType,
    } = this.props
    const { showExpiredRoleOnly, showGoldOnly, showGoldLightOnly, showFreeOnly, showInBlockOnly, showUnregistered } = this.state
    setParams('list', {
      languages,
      pageIndex,
      pageSize,
      filterKey,
      searchText,
      includeDeleted: true,
      country,
      sortBy,
      businessType,
      showExpiredRoleOnly,
      showGoldOnly,
      showGoldLightOnly,
      showFreeOnly,
      showInBlockOnly,
      showUnregistered,
      ...params,
    })
  }

  clickFilter = newFilterKey => {
    const { filterKey } = this.props
    if (filterKey !== newFilterKey) {
      this.props.history.push(`/company/${newFilterKey}`)
    }
  }

  onKeyUpSearch = event => {
    if (event.keyCode === 13) {
      this.refresh()
    }
  }

  drawAd() {
    const { filterKey } = this.props
    const bannerId = filterKey === 'blacklist' ? 587 : 585
    return (
      <div className="link-simple">
        <Banner bannerId={bannerId} />
      </div>
    )
  }

  renderFilterBar() {
    const { t, filterKey } = this.props
    const { categories } = this.state

    return (
      <Filter
        items={categories}
        // emptyOption={t('company.all-companies')}
        selectedKey={filterKey}
        name="filterKey"
        css={{
          container: 'panel-filter-md panel-filter-search panel-body-bordered',
          filter: 'priority-nav-has-dropdown',
        }}
        loadLanguages
        className="priority-nav-has-dropdown"
        onClick={this.clickFilter}
      >
        <div className="filter-container">
          <div className="search-container-secondary">
            <Field
              name="searchText"
              type="text"
              component="input"
              maxLength={50}
              placeholder={t('search')}
              onBlur={this.refresh}
              onKeyUp={this.onKeyUpSearch}
            />
            <button type="submit">
              <span className="fa fa-search" />
            </button>
          </div>
        </div>
      </Filter>
    )
  }
  requestForNewVerificationList = checked => {
    this.setState({ showOnlyVerificationRequests: checked, pageIndexVerified: 0, }, () => {
      this.getCompanyVerificationRequest(checked)
    })
  }

  requestForVerificationCompanies = verifiedOnly => {
    this.setState({ verifiedOnly }, () => {
      this.refresh({ verifiedOnly, pageIndex: 0 })
    })
  }
  requestForClosedCompanies = closedOnly => {
    this.setState({ closedOnly }, () => {
      this.refresh({ closedOnly, pageIndex: 0 })
    })
  }

  renderFilterBar2 = () => {
    const { t, permissions, langId, filterKey } = this.props
    const { showOnlyVerificationRequests, verifiedOnly, closedOnly } = this.state
    const isUser = filterKey === 'users'
    const canSearchDeleted = canDo(permissions, 'users', 'deleted')
    const canSearchNonregistered = canDo(permissions, 'users', 'nonregistered')

    const canSearchClosed = canDo(permissions, 'company', 'showClosedCompanies')

    const foundationType = isUser ? 'registration' : 'addition'
    const dictSortBy = [
      { name: t('by.activity.asc') + ' ↑', value: 'exp-date' },
      { name: t('by.activity.desc') + ' ↓', value: 'exp-date-desc' },
      { name: t('by.name') + ' ↑', value: 'name' },
      { name: t('by.name') + ' ↓', value: 'name-desc' },
      { name: t('by.rating') + ' ↑', value: 'rating' },
      { name: t('by.rating') + ' ↓', value: 'rating-desc' },
      { name: t(`by.${foundationType}.date`) + ' ↑', value: 'foundation-desc' },
      { name: t(`by.${foundationType}.date`) + ' ↓', value: 'foundation' },
      { name: t('by.country') + ' ↑', value: 'country' },
      { name: t('by.country') + ' ↓', value: 'country-desc' },
    ]

    const showVerificationCheckbox =
      filterKey !== 'users' && filterKey !== 'verification' && filterKey !== 'blacklist'

    return (
      <div className="panel-body-bordered">
        <div className="panel-filter panel-filter-sort">
          <div className="filter-wrapper">
            {filterKey === 'verification' && (
              <div className="filter-block filter-block-lg">
                <input
                  type="checkbox"
                  className="show-only-verification-requests"
                  id="ch20"
                  checked={showOnlyVerificationRequests}
                  onChange={e => this.requestForNewVerificationList(e.target.checked)}
                />
                <label htmlFor="ch20">{t('show.active.company.verification.requests')}</label>
              </div>
            )}
            {showVerificationCheckbox && (
              <div className="filter-block">
                <div className="checbox-container">
                  <input
                    type="checkbox"
                    id="ch5"
                    checked={verifiedOnly}
                    onChange={e => this.requestForVerificationCompanies(e.target.checked)}
                  />
                  <label htmlFor="ch5">{t('only.proved.companies')}</label>
                  <div className="has-tooltip">
                    <TooltipWrapper text={t('only.proved.companies')} hover>
                      <i className="fa fa-question-circle col-blue" />
                    </TooltipWrapper>
                  </div>
                </div>

                {canSearchClosed && (
                  <div className="checbox-container">
                    <input
                      type="checkbox"
                      id="ch6"
                      checked={closedOnly}
                      onChange={e => this.requestForClosedCompanies(e.target.checked)}
                    />
                    <label htmlFor="ch6">{t('only.closed.companies')}</label>
                    <div className="has-tooltip">
                      <TooltipWrapper text={t('only.closed.companies')} hover>
                        <i className="fa fa-question-circle col-blue" />
                      </TooltipWrapper>
                    </div>
                  </div>
                )}
              </div>
            )}
            {filterKey !== 'verification' && <div className="filter-block">
              <div className="select-container">
                <Field
                  name="country"
                  component={Dropdown}
                  items={dict.countries(langId, isUser ? 'U' : 'C')}
                  emptyOption={t('any.country')}
                />
              </div>
            </div>}
            {/*filterKey === 'users' && canSearchDeleted && (
              <div className="filter-block filter-block-lg">
                <CheckboxField name="includeDeleted">{t('company.show.deleted')}</CheckboxField>
              </div>
            )*/}
            {/* {filterKey === 'users' && canSearchNonregistered && (
              <div className="filter-block filter-block-lg">
                <CheckboxField name="includeNonregistered">
                  {t('company.show.nonregistered')}
                </CheckboxField>
              </div>
            )} */}
            {filterKey !== 'users' && filterKey !== 'verification' && (
              <div className="filter-block filter-block-lg">
                <div className="select-container">
                  <Field
                    name="businessType"
                    component={Dropdown}
                    items={dict.businessTypes(langId)}
                    emptyOption={t('company.empty.option')}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="filter-container">
            {filterKey !== 'verification' && <div className="filter-block">
              <label>{t('sort') + ':'}</label>
              <div className="select-container">
                <Field name="sortBy" component={Dropdown} items={dictSortBy} />
              </div>
            </div>}
            <div className="filter-block filter-block-sm">
              <label>{t('ads.quantity') + ':'}</label>
              <div className="select-container">
                <Field name="pageSize" component={Dropdown} items={dict.pageSizes} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  renderWidgetHeader = () => {
    const {
      t,
      permissions,
      profile: { companyId },
    } = this.props
    const canAdd = canDo(permissions, 'company', 'add')

    return (
      <WidgetHeader title={t('company.users.widget.title')}>
        {canAdd && !companyId && (
          <div>
            <div
              className="menu-item has-text"
              onClick={() => this.props.history.push('/company/add')}
            >
              <div>
                <Icon icon={plus} size={12} />
                <span>{t('company.create')}</span>
              </div>
            </div>
          </div>
        )}
      </WidgetHeader>
    )
  }

  getCategoryName = categoryId => {
    const { categories: { items = [] } = {} } = this.state
    const category = items.find(x => x.value === categoryId)
    return category && category.name
  }

  getItemLink = item => {
    if (this.props.filterKey === 'users') {
      return `/user/details/${item.user_id}`
    } else {
      return `/company/item/${item.companyId || item.id}`
    }
  }

  handleImageError = (companyId) => {
    const { errorsImages } = this.state
    const errors = []
    errors.push(companyId)
    this.setState({
      errorsImages: [...errorsImages, ...errors]
    })
  }

  renderCompanyVerificationTable = () => {
    const { t } = this.props
    const { companyRequestVerificationList, errorsImages } = this.state


    return (
      <div className="panel-content">
        <div
          className={classNames({
            'panel-content-table': true,
          })}
        >
          <table className="table table-striped-main table-link table-lg table-responsive js-table-responsive">
            <thead>
              <tr
                className={classNames({
                  'table-header': true,
                })}
              >
                <th>{t('company.name')}</th>
                <th>{t('request.date')}</th>
                <th>{t('selfie')}</th>
                <th>{t('company.document')}</th>
                <th>{t('personal.data.processing')}</th>
                <th>{t('employee.data')}</th>
                <th>{t('registration.data')}</th>
                <th>{t('application.status')}</th>
              </tr>
            </thead>

            <tbody>
              {companyRequestVerificationList.map((item, index2) => {
                const {
                  companyName,
                  createdOn,
                  selfie,
                  constituentDocument,
                  employeesAuthenticityConfirmation,
                  personalDataProcessingAgreement,
                  registrationAuthenticityConfirmation,
                  companyId,
                  active,
                  verifiedAt,
                  isValid,
                } = item

                const folder = `company/${companyId}`
                const imageError = errorsImages.findIndex(errId => Number(companyId) === Number(errId))
                return (
                  <tr
                    key={index2}
                    className={classNames({
                      'row-size': true,
                      'row-text-style': true,
                    })}
                  >
                    <td className="company company-align-start country-capitalize">
                      <Link to={() => this.getItemLink(item)}>
                        {companyName}
                      </Link>
                    </td>
                    <td className="rankings">{dateTimeFormatter(createdOn)}</td>
                    <td className="status">
                      <img
                        src={`${(config as any).apiUrl}/common/file/${folder}/${selfie}`}
                        alt="image"
                        style={{ maxWidth: 50 }}
                      />
                    </td>

                    <td className="feedback">
                      {imageError > -1 ?
                        <img src={`${(config as any).apiUrl}/common/file/pdf-icon-64.png`} className="max-width-50" /> :
                        <img
                          src={`${(config as any).apiUrl
                            }/common/file/${folder}/${constituentDocument}`}
                          alt="image"
                          style={{ maxWidth: 50 }}
                          onError={() => this.handleImageError(companyId)}
                        />}
                    </td>

                    <td>
                      <b>
                        {employeesAuthenticityConfirmation > 0 ? t('approved') : t('not.approved')}
                      </b>
                    </td>

                    <td>
                      <b>
                        {personalDataProcessingAgreement > 0 ? t('approved') : t('not.approved')}
                      </b>
                    </td>

                    <td>
                      <b>
                        {registrationAuthenticityConfirmation > 0
                          ? t('approved')
                          : t('not.approved')}
                      </b>
                    </td>
                    <td>{this.setVerificationOrderStatus(active, verifiedAt, isValid)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  setVerificationOrderStatus = (active, verifiedAt, isValid) => {
    const { t } = this.props
    let userStatus = t('unknow.status')
    if (active === 0) {
      userStatus = t('canceled.by.user')
    } else if (active === 1 && isValid === 0 && verifiedAt === null) {
      userStatus = t('in.review')
    } else if (active === 1 && isValid === 1 && verifiedAt !== null) {
      userStatus = t('approved')
    } else {
      userStatus = t('decline')
    }
    return userStatus
  }

  renderBlackListCompaniesTable = () => {
    const { t, activeItemId, filterKey, langId, businessType } = this.props
    const groups = this.getGroups()

    if (!groups) {
      return <div>{t('empty.black.list')}</div>
    }

    const { confirmItemId } = this.state

    const isUsers = filterKey === 'users'
    const content = groups.map((chunk, j) => {
      return (
        <div className="panel-content" key={j}>
          <div
            className={classNames({
              'panel-content-table': true,
              'panel-content-table-border': j === 0,
            })}
          >
            <table className="table table-striped-main table-link table-lg table-responsive js-table-responsive">
              {
                <thead>
                  <tr
                    className={classNames({
                      'tr-rounded-down': j > 0,
                      'table-header': true,
                      'hidden-table-header': j > 0,
                    })}
                  >
                    <th>{t(isUsers ? 'name' : 'title')}</th>
                    <th>{t('company.grid.rating')}</th>
                    <th>{t('company.grid.reviews')}</th>
                    <th>{t(isUsers ? 'block.list' : 'company.grid.claims')}</th>
                    <th>{t('company.grid.offers')}</th>
                    <th key={0}>{t('company.grid.peoples')}</th>
                    <th key={1}>{t('company.grid.business')}</th>
                    <th>{t('registration')}</th>
                    <th>{t('country')}</th>
                  </tr>
                </thead>
              }
              <tbody>
                {chunk.length === 0 && (
                  <tr className="tr-rounded-up">
                    <td colSpan={9} className="td-no-entries">
                      {t('no.entries')}
                    </td>
                  </tr>
                )}
                {chunk.map((item, index2) => {
                  return (
                    <tr
                      key={index2}
                      className={classNames({
                        'tr-rounded-up': index2 === chunk.length - 1,
                        'tr-rounded-down': index2 === 0 && j > 0,
                        'tr-highlighted': activeItemId === item.id || confirmItemId === item.id,
                        'row-size': true,
                        'row-text-style': true,
                        'row-disabled-company': item.isClosed === 1,
                      })}
                    >
                      <td className="company company-align-start country-capitalize">
                        <Link to={() => this.getItemLink(item)}>
                          <div className="verified-company-td">
                            <b>{item.name}</b>
                            {item.isValid > 0 && <i className="fa fa-check-circle col-blue" />}
                          </div>
                        </Link>
                      </td>

                      <td className="rankings">
                        <span className="mobile-prefix">{t('company.grid.rating') + ':'}</span>
                        <Link to={() => this.getItemLink(item)}>
                          {item.avgGrade || '-'}
                        </Link>
                      </td>
                      <td className="status">
                        <span className="mobile-prefix">{t('status') + ':'}</span>
                        <Link to={() => this.getItemLink(item)}>
                          {item.reviewCount || 0}
                        </Link>
                      </td>
                      {isUsers && (
                        <td className="feedback">
                          <span className="mobile-prefix">{t('company.grid.reviews') + ':'}</span>
                          <Link to={`/user/details/${item.user_id}`}>
                            {item.reviewCount}
                          </Link>
                        </td>
                      )}
                      <td className={isUsers ? 'block_list' : 'claims'}>
                        <span className="mobile-prefix">
                          {t(isUsers ? 'block.list' : 'company.grid.claims') + ':'}
                        </span>
                        <Link to={() => this.getItemLink(item)}>
                          {isUsers ? item.blockedCount || 0 : item.claimsCount || 0}
                        </Link>
                      </td>
                      <td className="suggestions">
                        <span className="mobile-prefix">{t('company.grid.offers') + ':'}</span>
                        <Link to={() => this.getItemLink(item)}>
                          {item.isClosed === 1 ? (
                            <>- / - / -</>
                          ) : (
                            <>
                              <TooltipWrapper text={t('cargo')} hover>
                                {item.cargoCount || 0}
                              </TooltipWrapper>{' '}
                              /{' '}
                              <TooltipWrapper text={t('transport')} hover>
                                {item.transportCount || 0}
                              </TooltipWrapper>{' '}
                              /{' '}
                              <TooltipWrapper text={t('ads.name')} hover>
                                {item.adsCount || 0}
                              </TooltipWrapper>
                            </>
                          )}
                        </Link>
                      </td>
                      {!isUsers && [
                        <td key={0} className="employees">
                          {item.isClosed === 1 ? (
                            <> - </>
                          ) : (
                            <>
                              <span className="mobile-prefix">
                                {t('company.grid.peoples') + ':'}
                              </span>
                              <Link to={`/company/item/${item.id}`}>
                                {item.userCount || 0}
                              </Link>
                            </>
                          )}
                        </td>,
                        <td key={1} className="business-type">
                          {item.isClosed === 1 ? (
                            t('company.not.active')
                          ) : (
                            <BussinesTypes
                              t={t}
                              itemId={item.id}
                              businessTypes={item.business_type}
                              langId={langId}
                              typesList={item.businesTypeIdList}
                              searchBusinnesType={businessType}
                            />
                          )}
                        </td>,
                      ]}

                      {isUsers && (
                        <td className="user-company">
                          <span className="mobile-prefix">{t('company') + ':'}</span>
                          <Link to={`/user/details/${item.user_id}`}>
                            {item.companyName || '-'}
                          </Link>
                        </td>
                      )}

                      <td className="foundation-year">
                        <span className="mobile-prefix">{t('registration') + ':'}</span>
                        <Link to={() => this.getItemLink(item)}>
                          {item.date_registered ? moment(item.date_registered).format("MM.YYYY") : '-'}
                        </Link>
                      </td>

                      <td className="country country-capitalize">
                        <span className="mobile-prefix">{t('country') + ':'}</span>
                        <Link to={() => this.getItemLink(item)}>
                          {item.country.toLowerCase()}
                        </Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {j !== groups.length - 1 && this.drawAd()}
        </div>
      )
    })
    return content
  }

  setSelectedChecked = (state, value) => {
    this.setState({
      [state]: value
    } as Pick<CompanyListState, keyof CompanyListState>, () => this.refresh({ pageIndex: 0 }))
  }

  setOnlyOneChecked = (state, value, container = "secondBlockCOntainer") => {
    if (container === "firstCheckBoxContainer") {
      this.setState({
        showExpiredRoleOnly: false,
        showUnregistered: false,
        showFreeOnly: false,
        showInBlockOnly: false,
      }, () => this.setSelectedChecked(state, value))
    } else {
      this.setState({
        showExpiredRoleOnly: false,
        showUnregistered: false,
        showFreeOnly: false,
        showInBlockOnly: false,
        showGoldOnly: false,
        showGoldLightOnly: false,
      }, () => this.setSelectedChecked(state, value))
    }

  }

  renderAdminFilterBar = () => {
    const { t } = this.props
    const { showExpiredRoleOnly, showGoldOnly, showGoldLightOnly, showFreeOnly, showInBlockOnly, showUnregistered } = this.state

    return (
      <div className="panel-body-bordered">
        <div className="panel-filter panel-filter-sort">

          <div className="checkboxes-container">
            <div>

              <div className="filter-block filter-block-lg">
                <input
                  type="checkbox"
                  className="show-only-expires-packets"
                  id="ch20"
                  checked={showExpiredRoleOnly}
                  onChange={e => this.setOnlyOneChecked("showExpiredRoleOnly", e.target.checked)}
                />
                <label htmlFor="ch20">{t('show.only.expires.packets')}</label>
              </div>



              <div className="filter-block filter-block-lg">
                <input
                  type="checkbox"
                  className="show-only-expires-packets"
                  id="chgl20"
                  checked={showUnregistered}
                  onChange={e => this.setOnlyOneChecked("showUnregistered", e.target.checked)}
                />
                <label htmlFor="chgl20">{t('show.only.unregistered')}</label>
              </div>
              <div className="filter-block filter-block-lg">
                <input
                  type="checkbox"
                  className="show-only-expires-packets"
                  id="chfree20"
                  checked={showFreeOnly}
                  onChange={e => this.setOnlyOneChecked("showFreeOnly", e.target.checked)}
                />
                <label htmlFor="chfree20">{t('show.only.free.packet')}</label>
              </div>

              <div className="filter-block filter-block-lg">
                <input
                  type="checkbox"
                  className="show-only-expires-packets"
                  id="chfree21"
                  checked={showInBlockOnly}
                  onChange={e => this.setOnlyOneChecked("showInBlockOnly", e.target.checked)}
                />
                <label htmlFor="chfree21">Показать заблокированных</label>
              </div>
            </div>
            <div>
              <div className="filter-block filter-block-lg">
                <input
                  type="checkbox"
                  className="show-only-expires-packets"
                  id="chgl29"
                  checked={showGoldLightOnly}
                  // onChange={e => this.setState({ showGoldLightOnly: e.target.checked }, () => this.refresh())}
                  onChange={e => this.setOnlyOneChecked("showGoldLightOnly", e.target.checked, "firstCheckBoxContainer")}
                />
                <label htmlFor="chgl29">{t('show.only.gold.light')}</label>
              </div>
              <div className="filter-block filter-block-lg">
                <input
                  type="checkbox"
                  className="show-only-expires-packets"
                  id="chg20"
                  checked={showGoldOnly}
                  // onChange={e => this.setState({ showGoldOnly: e.target.checked }, () => this.refresh())}
                  onChange={e => this.setOnlyOneChecked("showGoldOnly", e.target.checked, "firstCheckBoxContainer")}
                />
                <label htmlFor="chg20">{t('show.only.gold')}</label>
              </div>
            </div>
          </div>


        </div>
      </div>
    )
  }

  renderGrid = () => {
    const groups = this.getGroups()
    const { t, activeItemId, filterKey, loading, langId, businessType } = this.props
    const { confirmItemId, accessStatus, isAdmin } = this.state

    const isUsers = filterKey === 'users'
    return (
      <Fragment>
        <div className="panel">
          {this.renderWidgetHeader()}

          <div className="panel-body">
            {this.renderFilterBar()}
            {isAdmin && isUsers && this.renderAdminFilterBar()}
            <div className="panel-tab tabs-content">
              {this.renderFilterBar2()}

              {filterKey !== 'blacklist' && filterKey !== 'verification' && !loading ? (
                groups.map((chunk, j) => (
                  <div className="panel-content" key={j}>
                    <div
                      className={classNames({
                        'panel-content-table': true,
                        'panel-content-table-border': j === 0,
                      })}
                    >
                      <table className="table table-striped-main table-link table-lg table-responsive js-table-responsive">
                        {
                          <thead>
                            <tr
                              className={classNames({
                                'tr-rounded-down': j > 0,
                                'table-header': true,
                                'hidden-table-header': j > 0,
                              })}
                            >
                              <th>{t(isUsers ? 'name' : 'title')}</th>
                              <th>{t('company.grid.rating')}</th>
                              {isUsers && <th>{t('status')}</th>}
                              <th>{t('company.grid.reviews')}</th>
                              <th>{t(isUsers ? 'block.list' : 'company.grid.claims')}</th>
                              <th>{t('company.grid.offers')}</th>
                              {filterKey === 'items' && [
                                <th key={0}>{t('company.grid.peoples')}</th>,
                                <th key={1}>{t('company.grid.business')}</th>,
                              ]}
                              {isUsers && <th>{t('company')}</th>}
                              <th>{t('registration')}</th>
                              <th>{t('country')}</th>
                            </tr>
                          </thead>
                        }
                        <tbody>
                          {chunk.length === 0 && (
                            <tr className="tr-rounded-up">
                              <td colSpan={9} className="td-no-entries">
                                {t('no.entries')}
                              </td>
                            </tr>
                          )}
                          {chunk.map((item, index2) => {
                            return (
                              <tr
                                key={index2}
                                className={classNames({
                                  'tr-rounded-up': index2 === chunk.length - 1,
                                  'tr-rounded-down': index2 === 0 && j > 0,
                                  'tr-highlighted':
                                    activeItemId === item.id || confirmItemId === item.id,
                                  'row-size': true,
                                  'row-text-style': true,
                                  'row-disabled-company': item.isClosed === 1,
                                })}
                              >
                                <td className="company company-align-start country-capitalize">
                                  <div className="verif-company-container">
                                    <Link to={() => this.getItemLink(item)} >
                                      <div className="verified-company-td">
                                        <b>{item.name}</b>
                                        {item.isValid > 0 && (
                                          <TooltipWrapper text={t('company.verificated')} hover>
                                            <i className="fa fa-check-circle col-blue" />
                                          </TooltipWrapper>
                                        )}
                                      </div>
                                    </Link>
                                  </div>
                                </td>

                                <td className="rankings">
                                  <span className="mobile-prefix">
                                    {t('company.grid.rating') + ':'}
                                  </span>
                                  <Link to={() => this.getItemLink(item)} >
                                    {!isUsers ? item.avgGrade || '-' : item.averageRating || '-'}
                                  </Link>
                                </td>
                                <td className="status">
                                  <span className="mobile-prefix">{t('status') + ':'}</span>
                                  <Link to={() => this.getItemLink(item)} >
                                    {isUsers
                                      ? `${capitalize(item?.accessStatus?.toLowerCase() || 'Free')}`
                                      : item.reviewCount || 0}
                                  </Link>
                                  {isAdmin && isUsers && item.accessStatusExpires && item?.accessStatus?.toLowerCase() !== "free" &&
                                    <div className="status-date-expired">
                                      {t('to')}: {moment(item.accessStatusExpires).format("DD.MM.YYYY")}
                                    </div>}
                                </td>
                                {isUsers && (
                                  <td className="feedback">
                                    <span className="mobile-prefix">
                                      {t('company.grid.reviews') + ':'}
                                    </span>
                                    <Link to={`/user/details/${item.user_id}`} >
                                      {item.reviewCount}
                                    </Link>
                                  </td>
                                )}
                                <td className={isUsers ? 'block_list' : 'claims'}>
                                  <span className="mobile-prefix">
                                    {t(isUsers ? 'block.list' : 'company.grid.claims') + ':'}
                                  </span>
                                  <Link to={() => this.getItemLink(item)} >
                                    {isUsers ? item.blockedCount || 0 : item.claimsCount || 0}
                                  </Link>
                                </td>
                                <td className="suggestions">
                                  <span className="mobile-prefix">
                                    {t('company.grid.offers') + ':'}
                                  </span>
                                  <Link to={() => this.getItemLink(item)} >
                                    {item.isClosed === 1 ? (
                                      <>- / - / -</>
                                    ) : (
                                      <>
                                        <TooltipWrapper text={t('cargo')} hover>
                                          {item.cargoCount || 0}
                                        </TooltipWrapper>{' '}
                                        /{' '}
                                        <TooltipWrapper text={t('transport')} hover>
                                          {item.transportCount || 0}
                                        </TooltipWrapper>{' '}
                                        /{' '}
                                        <TooltipWrapper text={t('ads.name')} hover>
                                          {!isUsers ? item.adsCount || 0 : item.adCount || 0}
                                        </TooltipWrapper>
                                      </>
                                    )}
                                  </Link>
                                </td>
                                {!isUsers && [
                                  <td key={0} className="employees">
                                    {item.isClosed === 1 ? (
                                      <> - </>
                                    ) : (
                                      <>
                                        <span className="mobile-prefix">
                                          {t('company.grid.peoples') + ':'}
                                        </span>
                                        <Link to={`/company/item/${item.id}`} >
                                          {item.userCount || 0}
                                        </Link>
                                      </>
                                    )}
                                  </td>,
                                  <td key={1} className="business-type">
                                    {item.isClosed === 1 ? (
                                      t('company.not.active')
                                    ) : (
                                      <BussinesTypes
                                        t={t}
                                        itemId={item.id}
                                        businessTypes={item.business_type}
                                        langId={langId}
                                        typesList={item.businesTypeIdList}
                                        searchBusinnesType={businessType}
                                      />
                                    )}
                                  </td>,
                                ]}

                                {isUsers && (
                                  <td className="user-company">
                                    <span className="mobile-prefix">{t('company') + ':'}</span>
                                    <Link to={`/user/details/${item.user_id}`} >
                                      {item.companyName || '-'}
                                    </Link>
                                  </td>
                                )}

                                <td className="foundation-year">
                                  <span className="mobile-prefix">{t('registration') + ':'}</span>
                                  <Link to={() => this.getItemLink(item)} >
                                    {!isUsers
                                      ? item.date_registered
                                        ? moment(item.date_registered).format("MM.YYYY")
                                        : '-'
                                      : item.dateRegistered
                                        ? moment(item.dateRegistered).format("DD.MM.YYYY")
                                        : '-'}
                                  </Link>
                                </td>

                                <td className="country country-capitalize">
                                  <span className="mobile-prefix">{t('country') + ':'}</span>
                                  <Link to={() => this.getItemLink(item)} >
                                    {item.country.toUpperCase()}
                                  </Link>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    {j !== groups.length - 1 && this.drawAd()}
                  </div>
                ))
              ) : filterKey === 'verification' && accessStatus === 'Admin' ? (
                this.renderCompanyVerificationTable()
              ) : !loading ? (
                // TODO: Create blacklist functionality and table
                this.renderBlackListCompaniesTable()
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  renderTutorialModal = () => {
    const { t, showTutorialJoinCompanyPopup } = this.props
    confirmAlert({
      title: t('company.tutorial'),
      message: t('company.tutorial.text') as any,
      buttons: [
        {
          label: t('company.tutorial.ok'),
          onClick: () => showTutorialJoinCompanyPopup(),
        },
      ],
      onClickOutside: () => showTutorialJoinCompanyPopup(),
      onKeypressEscape: () => showTutorialJoinCompanyPopup(),
    })
  }

  render() {
    const { total, pageIndex, pageSize, filterKey, showTutorialPopup } = this.props
    const {
      pageIndexVerified,
      pageSizeVerified,
      totalVerified,
      accessStatus,
      verifiedOnly,
      closedOnly,
    } = this.state
    const pagination = { total, pageIndex, pageSize }

    const paginationVerified = {
      total: totalVerified,
      pageIndex: pageIndexVerified,
      pageSize: pageSizeVerified,
    }

    let finalPagination
    if (accessStatus === 'Admin' && filterKey === 'verification') {
      finalPagination = paginationVerified
    } else {
      finalPagination = pagination
    }

    const bannerId = filterKey === 'blacklist' ? 587 : 585

    return (
      <ModalConsumer>
        {({ showModalComponent, clearModal }) => {
          this.showModalComponent = showModalComponent
          this.clearModal = clearModal
          return (
            <div className={`${STYLE_BASE}wrapper`}>
              {showTutorialPopup && this.renderTutorialModal()}
              {this.renderGrid()}
              {finalPagination.total <= SHOW_ADS_MIN_ITEMS && (
                <div className="empty-banner-content">
                  <Banner bannerId={bannerId} />
                </div>
              )}
              <Pagination
                {...finalPagination}
                onClick={pageIndex => {
                  this.refresh({ pageIndex, verifiedOnly, closedOnly })
                }}
              />
              {/* {filterKey !== 'blacklist' &&
                (filterKey === 'verification' || accessStatus !== 'Admin') && (
                  <Pagination
                    {...finalPagination}
                    onClick={pageIndex => {
                      this.refresh({ pageIndex, verifiedOnly, closedOnly })
                    }}
                  />
                )} */}
            </div>
          )
        }}
      </ModalConsumer>
    )
  }
}
