import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { Link, Redirect } from 'react-router-dom'

import Spinner from '../../UI/spinner'
import Filter from '../../shared/components/widget-filter'
import dict from '../../shared/dictionaries'
import { ISummaryTableProps, ISummaryTableState, Direction } from './types'

import './style.scss'

export default class extends Component<ISummaryTableProps, ISummaryTableState> {
  constructor(props) {
    super(props)
    this.state = {
      selectedKey: 'all',
      isCustomize: false,
      isFavorite: false,
      isHidden: false,
      mobileFilter: 'cargo',
      isBlocked: false,
      isTransition: false,
      redirectPage: null,
    }
  }

  componentDidMount() {
    this.updateTable()
  }

  componentDidUpdate(prevProps: ISummaryTableProps) {
    if (prevProps.userId !== this.props.userId) {
      this.updateTable()
    }
  }

  updateTable = () => {
    const { selectedKey, isBlocked, isCustomize, isFavorite } = this.state
    const { getSummaryTable, langId, entity, userId } = this.props
    if (entity === 'intranational') {
      getSummaryTable({
        shippingType: selectedKey !== 'all' ? selectedKey : null,
        isLocal: true,
        isBlocked,
        userId,
        langId,
      })
    } else {
      getSummaryTable({
        isLocal: false,
        langId,
        userId,
        isFavorites: isCustomize ? false : isFavorite,
      })
    }
  }

  changeFilter = selectedKey => {
    const { isBlocked, isCustomize, isFavorite } = this.state
    const { getSummaryTable, langId, userId } = this.props
    this.setState({ selectedKey, isTransition: false }, () => {
      getSummaryTable({
        shippingType: selectedKey !== 'all' ? selectedKey : null,
        isLocal: true,
        isBlocked,
        userId,
        langId,
      })

      getSummaryTable({
        isLocal: false,
        shippingType: selectedKey !== 'all' ? selectedKey : null,
        langId,
        userId,
        isFavorites: isCustomize ? false : isFavorite,
      })
    })
  }

  changeFavoriteFilter = isFavorite => {
    this.setState({ isFavorite }, () => {
      this.updateTable()
    })
  }

  changeHidden = () => {
    this.setState({ isHidden: !this.state.isHidden, isTransition: true })
  }

  changeBlocked = () => {
    this.setState({ isBlocked: !this.state.isBlocked, isTransition: false }, () => {
      this.updateTable()
    })
  }

  changeCustomize = () => {
    this.setState({ isCustomize: !this.state.isCustomize }, () => {
      this.updateTable()
    })
  }

  handleRedirect = (
    searchType: SearchType,
    countryId: number,
    isToday: boolean,
    direction: Direction,
    sameCountryOnly?: boolean
  ) => {
    const { isBlocked } = this.state
    let redirectPage = `/${searchType}/search?`

    if (countryId) {
      if (direction === 'both') {
        redirectPage += `from=${countryId}&to=${countryId}`
      } else if (direction === 'in') {
        redirectPage += `to=${countryId}`
      } else if (direction === 'out') {
        redirectPage += `from=${countryId}`
      }
      if (isToday) {
        redirectPage += '&'
      }
    }

    if (sameCountryOnly) {
      redirectPage += 'sameCountryOnly=true'
    }
    const additionalSymbol = sameCountryOnly ? "&" : ""
    if (isToday) {

      redirectPage += `${additionalSymbol}filterKey=today`
    } else {
      redirectPage += `${additionalSymbol}filterKey=all`
    }

    if (isBlocked) {
      redirectPage += '&block=true'
    }

    this.setState({ redirectPage })
  }

  changeFavorite = (countryId, isFavorite) => {
    const { changeSummaryFavorite } = this.props
    changeSummaryFavorite({ countryId, isFavorite })
  }

  render() {
    const { t, entity, summary, isAuthenticated, isWidget, dataMoveForMobile = "" } = this.props
    const {
      selectedKey,
      isHidden,
      isFavorite,
      isCustomize,
      mobileFilter,
      isTransition,
      isBlocked,
      redirectPage,
    } = this.state
    const isInternational = entity === 'international'
    const tableInfo = isInternational ? summary.international : summary.intranational
    const tableData = tableInfo.data
    const isLoading = tableInfo.loading
    const colMultiplier = isInternational ? 2 : 1
    const filterItems = isInternational
      ? dict.summaryTableFilterItems(t)
      : dict.summaryTableShippingTypes(t)

    if (redirectPage) {
      return <Redirect to={redirectPage} />
    }

    return (
      <div
        data-move={dataMoveForMobile}
        className={classNames({
          'summary-table-panel': true,
          'international-summary-table-panel': isInternational,
          'intranational-summary-table-panel': !isInternational,
          'widget-summary-table-panel': isWidget,
        })}
      >
        {!isInternational && (
          <div className="summary-panel">
            <Filter
              items={filterItems}
              selectedKey={selectedKey}
              name="filterKey"
              loadLanguages
              onClick={this.changeFilter}
            />
            {/* {!isWidget && isAuthenticated && (
              <Fragment>
                <input type="checkbox" id="summary-blocklist" checked={isBlocked} />
                <label htmlFor="summary-blacklist" onClick={this.changeBlocked}>
                  {t('summary.results.blocklist')}
                </label>
              </Fragment>
            )} */}
          </div>
        )}
        <div
          className="summary-table-title summary-panel summary-panel-space-between"
          onClick={() => !isInternational && this.changeHidden()}
        >
          <h6>
            {isInternational
              ? t('summary.international.transportation')
              : t('summary.intranational.transportation')}
          </h6>
          {!isInternational && (
            <i className={isHidden ? 'fa fa-chevron-down' : 'fa fa-chevron-up'} />
          )}
        </div>
        {isInternational && isAuthenticated && (
          <div className="summary-panel summary-panel-space-between">
            <div>
              {!isCustomize && (
                <Filter
                  items={filterItems}
                  selectedKey={isFavorite}
                  name="filterKey"
                  loadLanguages
                  onClick={this.changeFavoriteFilter}
                />
              )}
            </div>
            <div>
              <Link
                to="#"
                title={isCustomize ? t('summary.back') : t('summary.favorites')}
                className="summary-customize"
                onClick={this.changeCustomize}
              >
                {isCustomize ? (
                  <span>{t('summary.back')}</span>
                ) : (
                  <Fragment>
                    <i className="fa fa-cog" />
                    <span> {t('summary.favorites')}</span>
                  </Fragment>
                )}
              </Link>
            </div>
          </div>
        )}
        <div
          className={classNames({
            'summary-hidden-animation': !isInternational && isHidden,
            'summary-hidden-panel': isTransition,
          })}
          style={{ maxHeight: tableData.length * 25 + 130 }}
        >
          <div className="summary-mobile-on summary-panel">
            <div
              className={classNames({
                'mobile-item': true,
                'mobile-active': mobileFilter === 'cargo',
              })}
              onClick={() => this.setState({ mobileFilter: 'cargo' })}
            >
              <i className="fa fa-cogs" />
              <span> {t('cargo')}</span>
            </div>
            <div
              className={classNames({
                'mobile-item': true,
                'mobile-active': mobileFilter === 'transport',
              })}
              onClick={() => this.setState({ mobileFilter: 'transport' })}
            >
              <i className="fa fa-truck" />
              <span> {t('transport')}</span>
            </div>
          </div>
          <table
            className={classNames({
              'summary-table': true,
              'international-summary-table': isInternational,
            })}
          >
            <thead>
              <tr>
                <th rowSpan={3} className="summary-table-country">
                  {t('country')}
                </th>
                <th colSpan={2 * colMultiplier} className="summary-mobile-off">
                  <i className="fa fa-cogs" />
                  <span> {t('cargo')}</span>
                </th>
                <th colSpan={2 * colMultiplier} className="summary-mobile-off">
                  <i className="fa fa-truck" />
                  <span> {t('transport')}</span>
                </th>
              </tr>
              <tr>
                <th
                  colSpan={colMultiplier}
                  onClick={() => this.handleRedirect('cargo', null, true, 'both', !isInternational)}
                  className="summary-pointer"
                >
                  {t('summary.today')}
                </th>
                <th
                  colSpan={colMultiplier}
                  onClick={() => this.handleRedirect('cargo', null, false, 'both', !isInternational)}
                  className="summary-pointer"
                >
                  {t('statistics.total')}
                </th>
                <th
                  colSpan={colMultiplier}
                  onClick={() => this.handleRedirect('transport', null, true, 'both', !isInternational)}
                  className="summary-mobile-off summary-pointer"
                >
                  {t('summary.today')}
                </th>
                <th
                  colSpan={colMultiplier}
                  onClick={() => this.handleRedirect('transport', null, false, 'both', !isInternational)}
                  className="summary-mobile-off summary-pointer"
                >
                  {t('statistics.total')}
                </th>
              </tr>
              {isInternational && (
                <tr>
                  <th>{t('from2')}</th>
                  <th>{t('in')}</th>
                  <th>{t('from2')}</th>
                  <th>{t('in')}</th>
                  <th className="summary-mobile-off">{t('from2')}</th>
                  <th className="summary-mobile-off">{t('in')}</th>
                  <th className="summary-mobile-off">{t('from2')}</th>
                  <th className="summary-mobile-off">{t('in')}</th>
                </tr>
              )}
            </thead>
            {isLoading ? (
              <Spinner />
            ) : (
              <tbody>
                {!tableData.length && (
                  <tr>
                    <td colSpan={10} className="summary-not-found">
                      {t('not.found')}
                    </td>
                  </tr>
                )}
                {tableData.map(row => (
                  <tr key={row.countryId}>
                    <td className="summary-table-country">
                      {isCustomize && isInternational && (
                        <Fragment>
                          <input
                            type="checkbox"
                            id={`summary-favorite-${row.countryId}`}
                            checked={row.isFavorite}
                            onChange={() => this.changeFavorite(row.countryId, row.isFavorite)}
                          />
                          <label htmlFor={`summary-favorite-${row.countryId}`}></label>
                        </Fragment>
                      )}
                      {row.countryName}
                    </td>
                    <td
                      onClick={() =>
                        row.outCargoToday &&
                        this.handleRedirect(
                          'cargo',
                          row.countryId,
                          true,
                          isInternational ? 'out' : 'both'
                        )
                      }
                      className={classNames({
                        'summary-mobile-off': mobileFilter !== 'cargo',
                        'summary-pointer': row.outCargoToday,
                      })}
                    >
                      {row.outCargoToday ? row.outCargoToday : null}
                    </td>
                    {isInternational && (
                      <td
                        onClick={() =>
                          row.inCargoToday &&
                          this.handleRedirect('cargo', row.countryId, true, 'in')
                        }
                        className={classNames({
                          'summary-mobile-off': mobileFilter !== 'cargo',
                          'summary-pointer': row.inCargoToday,
                        })}
                      >
                        {row.inCargoToday ? row.inCargoToday : null}
                      </td>
                    )}
                    <td
                      onClick={() =>
                        row.outCargoTotal &&
                        this.handleRedirect(
                          'cargo',
                          row.countryId,
                          false,
                          isInternational ? 'out' : 'both'
                        )
                      }
                      className={classNames({
                        'summary-mobile-off': mobileFilter !== 'cargo',
                        'summary-pointer': row.outCargoTotal,
                      })}
                    >
                      {row.outCargoTotal ? row.outCargoTotal : null}
                    </td>
                    {isInternational && (
                      <td
                        onClick={() =>
                          row.inCargoTotal &&
                          this.handleRedirect('cargo', row.countryId, false, 'in')
                        }
                        className={classNames({
                          'summary-mobile-off': mobileFilter !== 'cargo',
                          'summary-pointer': row.inCargoTotal,
                        })}
                      >
                        {row.inCargoTotal ? row.inCargoTotal : null}
                      </td>
                    )}
                    <td
                      onClick={() =>
                        row.outTransportToday &&
                        this.handleRedirect(
                          'transport',
                          row.countryId,
                          true,
                          isInternational ? 'out' : 'both'
                        )
                      }
                      className={classNames({
                        'summary-mobile-off': mobileFilter !== 'transport',
                        'summary-pointer': row.outTransportToday,
                      })}
                    >
                      {row.outTransportToday ? row.outTransportToday : null}
                    </td>
                    {isInternational && (
                      <td
                        onClick={() =>
                          row.inTransportToday &&
                          this.handleRedirect('transport', row.countryId, true, 'in')
                        }
                        className={classNames({
                          'summary-mobile-off': mobileFilter !== 'transport',
                          'summary-pointer': row.inTransportToday,
                        })}
                      >
                        {row.inTransportToday ? row.inTransportToday : null}
                      </td>
                    )}
                    <td
                      onClick={() =>
                        row.outTransportTotal &&
                        this.handleRedirect(
                          'transport',
                          row.countryId,
                          false,
                          isInternational ? 'out' : 'both'
                        )
                      }
                      className={classNames({
                        'summary-mobile-off': mobileFilter !== 'transport',
                        'summary-pointer': row.outTransportTotal,
                      })}
                    >
                      {row.outTransportTotal ? row.outTransportTotal : null}
                    </td>
                    {isInternational && (
                      <td
                        onClick={() =>
                          row.inTransportTotal &&
                          this.handleRedirect('transport', row.countryId, false, 'in')
                        }
                        className={classNames({
                          'summary-mobile-off': mobileFilter !== 'transport',
                          'summary-pointer': row.inTransportTotal,
                        })}
                      >
                        {row.inTransportTotal ? row.inTransportTotal : null}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    )
  }
}
